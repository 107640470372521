import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledHeader = styled.header`
  font-family: "Latin Modern Mono";
  // margin: 0 auto;
  margin-top: -1rem;
  margin-bottom: 4rem;
  // text-align: center;
  h1, a {
    text-decoration: none;
    margin: 0;
    font-weight: normal;
  }
  #title {
    padding: 0 .6rem .3rem;
    font-size: .6rem;
    color: grey;
    // text-shadow: 0 0 2px white, 0 0 3px white, 0 0 4px white, 0 0 8px white, 0 0 10px white;
    // background: white;
  }
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <h1>
      <Link to="/" id="title">
        naru's news updates
      </Link>
    </h1>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
