/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

const LayoutBody = styled.div`
  margin: 0 auto;
  max-width: 800px;
  min-height: 82vh;
  padding: 0 10%;
`

const StyledFooter = styled.footer`
  margin: auto;
  padding-top: .5rem;
  text-align: center;
  font-size: .7rem;
  font-family: "Latin Modern Mono", sans-serif;
  #IG {
    display: block;
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-decoration: none;
  }
`

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <LayoutBody>
        <main>
          {children}
        </main>
      </LayoutBody>
      {/* <StyledFooter>
        <a href="http://instagram.com/user_naru" id="IG" title="to insta">🚮</a>
        © {new Date().getFullYear()}
      </StyledFooter> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
